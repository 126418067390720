.page {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.centered_card {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--white);
  border-radius: 6px;
  padding: 10px 10px 5px;
}

.logo {
  min-width: 480px;
  max-width: 700px;
  width: 100%;
  height: auto;
}
